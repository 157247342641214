import React, { useMemo } from 'react';
import { ActivityIndicator, FlatList, useWindowDimensions } from 'react-native';
import { Modal, Text, View, Image, Pressable, } from 'react-native';
// import { styles } from './style.js';
import { COUNTRY_CODE, COUNTRY_ICON, COUNTRY_NAME, USER_ID, getSession, saveSession } from './LocalStorage.js';
import { COUNTRY } from './webUtils.js';
import moment from 'moment';
import { colors } from './color.js';
import { ids, styles } from './responsive.js'

// //progress bar
export const CustomProgressBar = ({ visible }) => (
    <Modal
        onRequestClose={null}
        transparent
        visible={visible}
    // animationType={'slide'}
    >
        <View style={styles.customProgressBarView}>
            <View style={[styles.customProgressBarSubView]}>

                {/* <Image source={require("../assets/logo.png")}
                    style={{
                        height: 200,
                        width: 300,
                        resizeMode: "contain"
                    }} /> */}
                    <ActivityIndicator color={colors.buttonColor} size={"large"}/>
                <Text style={styles.customProgressBarLoadingText}>Loading....</Text>
            </View>
        </View>
    </Modal>
);

//for console method

// custom console
export const CustomConsole = (visible) => {
    console.log(visible);
}

// .00 digits
export const CustomDigits = (text) => {
    return parseFloat(text).toFixed(2);
}

// //empty state
// export const CustomEmptyState = ({ text }) => (
//     <View style={styles.emptyMainContainer}>
//         <Text style={styles.emptyText}>
//             {text}
//         </Text>
//     </View>
// );

//validate email method
export const validateEmail = (text) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(text) === false) {
        return false;
    }
    else {
        return true;
    }
}

// custom fonts
export const getBebasNeueRegularFont = () => {
    return "BebasNeue-Regular"
}
export const getPlusJakartaSansBoldFont = () => {
    return "PlusJakartaSans-Bold"
}
export const getPlusJakartaSansExtraBoldFont = () => {
    return "PlusJakartaSans-ExtraBold"
}
export const getPlusJakartaSansExtraLightFont = () => {
    return "PlusJakartaSans-ExtraLight"
}
export const getPlusJakartaSansLightFont = () => {
    return "PlusJakartaSans-Light"
}
export const getPlusJakartaSansMediumFont = () => {
    return "PlusJakartaSans-Medium"
}
export const getPlusJakartaSansRegularFont = () => {
    return "PlusJakartaSans-Regular"
}
export const getPlusJakartaSansSemiBoldFont = () => {
    return "PlusJakartaSans-SemiBold"
}
export const getPlusJakartaSansVariableFont_wghtFont = () => {
    return "PlusJakartaSans-VariableFont_wght"
}


// export const RequestLogPrint = (url, formdata) => {
//     // CustomConsole(url);
//     // CustomConsole("REQUEST=>");
//     for (const part of formdata.getParts()) {
//         CustomConsole(part.fieldName + ":" + part.string);
//     }
//     // CustomConsole("RESPONSE=>");
// }

// HeaderComponent view
export const HeaderComponent = (text, navigation, userId) => {

    const [countryList, setCountryList] = React.useState([]);
    const [loading, setLoading] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [countryIcon, setCountryIcon] = React.useState('');
    const [countryId, setCountryId] = React.useState('');

    React.useEffect(() => {
        // countryListApi();
    }, [countryList.length == 0]);

    // country list api
    const countryListApi = async () => {
        try {
            const country_code = await getSession(COUNTRY_CODE);
            const country_name = await getSession(COUNTRY_NAME);
            const country_icon = await getSession(COUNTRY_ICON);

            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            CustomConsole(COUNTRY);

            // setLoading(true);
            fetch(COUNTRY, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    countryList.length = 0;

                    if (country_code != undefined) {
                        setCountry(country_name);
                        setCountryIcon(country_icon);
                        setCountryId(country_code);
                    } else {
                        setCountry(json.data[0].country_name);
                        setCountryIcon(json.data[0].country_icon);
                        setCountryId(json.data[0].country_id);

                        saveSession(COUNTRY_CODE, json.data[0].country_id);
                        saveSession(COUNTRY_NAME, json.data[0].country_name);
                        saveSession(COUNTRY_ICON, json.data[0].country_icon);
                    }

                    for (let i = 0; i < json.data.length; i++) {

                        countryList.push({
                            index: i,
                            country_id: json.data[i].country_id,
                            country_name: json.data[i].country_name,
                            country_icon: json.data[i].country_icon,
                        });
                    }

                    // setCountryList(countryList);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Country Api Error=>" + error);
                });
        } catch (e) {
            CustomConsole("Country Exception=>" + e + "");
        }
    }

    const { width } = useWindowDimensions();
    const [visible, setVisible] = React.useState(false);
    const [countryVisible, setCountryVisible] = React.useState(false);

    // dialog box 
    const showCountryDialog = () => {
        setCountryVisible(true)
    };

    const hideCountryDialog = () => setCountryVisible(false);

    // dialog box 
    const showDialog = () => {
        setVisible(true)
    };

    const hideDialog = () => setVisible(false);

    const WIGGLE_ROOM = 128;

    const offset = useMemo(() => {
        return {
            top: Math.random() * WIGGLE_ROOM - WIGGLE_ROOM / 2,
            left: Math.random() * WIGGLE_ROOM - WIGGLE_ROOM / 2
        };
    }, []);

    // common navigation method
    const onClickOfMenu = (screenName, params) => {
        hideDialog();
        if (screenName == "CommonScreen") {
            navigation.navigate(screenName, { paramScreen: params });
        } else {
            navigation.navigate(screenName);
        }
    }

    return (
        <View style={styles.headerMainView} dataSet={{ media: ids.headerMainView }}>

            {/* app logo */}
            <View style={{}}>
                <Pressable onPress={() => navigation.navigate("HomeScreen")}>
                {/* <Pressable onPress={() => navigation.navigate("TicketConfirmScreen", {
                    paramEventId: "1",
                    paramEventTicketType: [],
                    paramEventTicketTotal: 150,
                    paramEventName: "test",
                    paramEventDate: "November 29, 2025",
                    paramEventTime: "8:30 an",
                    paramEventLocation: "test",
                    clientSecret:"test"
                })}> */}
                    <Image source={require("../assets/logo.png")} style={styles.headerLogo} dataSet={{ media: ids.headerLogo }} />
                </Pressable>
            </View>
            {/* end of app logo */}

            <View style={styles.headerSubView}>
                {/* <Picker
                    style={{ color: colors.white, marginTop: 5, paddingHorizontal: 10, backgroundColor: colors.black, height: 40, borderWidth: 1, borderRadius: 5, borderColor: '#9A9A9A', width: 100, marginRight: 15 }}
                    selectedValue={country}
                    onValueChange={(itemValue, itemIndex) => {
                        setCountry(itemValue)
                    }}>
                    <Picker.Item label="Please Select" value="" />
                    {countryList.map((item) => (
                        <Picker.Item label={item.country_name} value={item.country_name} key={item.country_name} />
                    ))}
                </Picker> */}

                {/* country selection */}
                {/* <Pressable onPress={() => showCountryDialog()}
                    style={styles.headerCountrySelection}>
                    <Image source={{ uri: countryIcon }} style={styles.headerCountrySelectionIcon} />
                    <Image source={require("../assets/down_arrow.png")} style={styles.headerCountrySelectionArrow} />
                </Pressable> */}
                {/* end of country selection */}

                <View style={styles.headerPressableView} dataSet={{ media: ids.headerPressableView }}>
                    <Pressable onPress={() => navigation.navigate("UpcomingEventsListScreen")} style={styles.headerPressableSubView}>
                        <Text style={styles.headerPressableText}>Events</Text>
                    </Pressable>
                    {userId == null || userId == "" ?
                        <Pressable onPress={() => navigation.navigate("LoginScreen")} style={styles.headerPressableSubView}>
                            <Text style={styles.headerPressableText}>Login</Text>
                        </Pressable>
                        :
                        <Pressable onPress={() => navigation.navigate("ProfileScreen")} style={styles.headerPressableSubView}>
                            <Text style={styles.headerPressableText}>Profile</Text>
                        </Pressable>}

                </View>

                {/* menu icon */}
                <Pressable onPress={() => showDialog()} style={styles.headerMenuIconView} dataSet={{ media: ids.headerMenuIconView }}>
                    <Image source={require("../assets/menuicon.png")} style={styles.headerMenuIcon} />
                </Pressable>
                {/* end of menu icon */}
            </View>

            {/* drawer modal */}
            <Modal
                onRequestClose={hideDialog}
                transparent
                visible={visible}
                animationType={'slide'}>
                <View style={styles.headerModalMainView}>
                    <View style={[styles.headerModalSubView, 0]}>
                        <Pressable onPress={hideDialog} style={styles.headerModalCloseButtonView}>
                            <Image source={require("../assets/modal_close.png")} style={styles.headerModalCloseButtonImage} />
                        </Pressable>
                        <View style={styles.headerModalLinksView} dataSet={{ media: ids.headerModalLinksView }}>
                            <View>
                                <Pressable onPress={() => onClickOfMenu("HomeScreen")}>
                                    <Text style={styles.headerModalLinkText}>Home</Text>
                                </Pressable>
                                <Pressable onPress={() => onClickOfMenu("UpcomingEventsListScreen")}>
                                    <Text style={styles.headerModalLinkText}>Events</Text>
                                </Pressable>
                                {/* <Pressable onPress={() => onClickOfMenu("OfferListScreen")}>
                                    <Text style={styles.headerModalLinkText}>Offer</Text>
                                </Pressable> */}
                                {userId == null || userId == "" ?
                                    <Pressable onPress={() => onClickOfMenu("LoginScreen")}>
                                        <Text style={styles.headerModalLinkText}>Login</Text>
                                    </Pressable>
                                    :
                                    <Pressable onPress={() => onClickOfMenu("ProfileScreen")}>
                                        <Text style={styles.headerModalLinkText}>Profile</Text>
                                    </Pressable>
                                }
                                <Pressable onPress={() => onClickOfMenu("CommonScreen", "about")}>
                                    <Text style={styles.headerModalLinkText}>About Us</Text>
                                </Pressable>
                                <Pressable onPress={() => onClickOfMenu("ContactScreen")}>
                                    <Text style={styles.headerModalLinkText}>Contact Us</Text>
                                </Pressable>
                            </View>
                            <View style={styles.headerModalLinkSecondView} dataSet={{media:ids.headerModalLinkSecondView}}>
                                <Pressable onPress={() => onClickOfMenu("CommonScreen", "terms")}>
                                    <Text style={styles.headerModalLinkText}>Term & condition</Text>
                                </Pressable>
                                <Pressable onPress={() => onClickOfMenu("CommonScreen", "return")}>
                                    <Text style={styles.headerModalLinkText}>Refund policy</Text>
                                </Pressable>
                                <Pressable onPress={() => onClickOfMenu("CommonScreen", "privacy")}>
                                    <Text style={styles.headerModalLinkText}>Privacy policy</Text>
                                </Pressable>
                                <Pressable onPress={() => onClickOfMenu("CommonScreen", "disclaimer")}>
                                    <Text style={styles.headerModalLinkText}>Disclaimer</Text>
                                </Pressable>
                            </View>
                        </View>
                    </View>
                </View>
            </Modal>
            {/* end of drawer modal */}

            {/* country modal */}
            <Modal
                onRequestClose={hideCountryDialog}
                transparent
                visible={countryVisible}
                animationType={'slide'}>
                <View style={styles.headerCountryModalMainView}>
                    <View style={[styles.headerCountryModalSubView, 500]}>
                        <Pressable onPress={hideCountryDialog} style={styles.headerCountryModalCloseView}>
                            <Image source={require("../assets/modal_close.png")} style={styles.headerCountryModalCloseImage} />
                        </Pressable>
                        <FlatList style={{}}
                            data={countryList}
                            showsVerticalScrollIndicator={false}
                            ItemSeparatorComponent={<View style={styles.headerCountrySeparator} />}
                            renderItem={({ item, index }) => {
                                return (
                                    <Pressable onPress={() => {
                                        setCountry(item.country_name);
                                        setCountryIcon(item.country_icon);
                                        setCountryId(item.country_id);

                                        saveSession(COUNTRY_CODE, item.country_id);
                                        saveSession(COUNTRY_NAME, item.country_name);
                                        saveSession(COUNTRY_ICON, item.country_icon);

                                        window.location.reload();
                                        hideCountryDialog();
                                    }}
                                        style={styles.headerCountryListView}>
                                        <Image source={{ uri: item.country_icon }} style={styles.headerCountryListIcon} />
                                        <Text style={styles.headerCountryListText}>{item.country_name}</Text>
                                    </Pressable>
                                );
                            }} />
                    </View>
                </View>
            </Modal>
            {/* end of country modal */}
        </View>
    );
}

// screen background 
export const BackgroundView = () => {
    return (
        <View style={styles.backgroundMainView} dataSet={{ media: ids.backgroundMainView }}>
            <Image source={require("../assets/gariny-bg2.png")}
                style={styles.backgroundImage1}
            />
            <FlatList style={styles.backgroundMultipleImageList}
                data={["1", "2", "3", "4", "5"]}
                renderItem={(item) => {
                    return (
                        <Image source={require("../assets/gariny-bg2.png")}
                            style={styles.backgroundImage2}
                        />
                    );
                }}
            />
        </View>
    );
}

// custom border
export const CustomBorder = () => {
    return (
        <View style={styles.customBorder} />
    );
}

// FooterComponent view
export const FooterComponent = (navigation) => {

    return (
        <View style={styles.footerMainView} dataSet={{ media: ids.footerMainView }}>
            {/* subview 1 */}
            <View style={styles.footerSubView1}>
                <Image source={require("../assets/gariny-bg2.png")}
                    style={styles.backImg}
                />
                <Image source={require("../assets/logo.png")} style={styles.footerLogo} dataSet={{ media: ids.footerLogo }} />
                <View style={styles.linkView1} dataSet={{ media: ids.linkView1 }}>
                    <Pressable onPress={() => navigation.navigate("HomeScreen")}>
                        <Text style={styles.linkViewTextWithRightBorder} dataSet={{ media: ids.linkViewTextWithRightBorder }}>Home</Text>
                    </Pressable>
                    <Pressable onPress={() => navigation.navigate("UpcomingEventsListScreen")}>
                        <Text style={styles.linkViewTextWithRightBorder} dataSet={{ media: ids.linkViewTextWithRightBorder }}>Events</Text>
                    </Pressable>
                    {/* <Pressable onPress={() => navigation.navigate("OfferListScreen")}>
                        <Text style={styles.linkViewTextWithRightBorder}>Offer</Text>
                    </Pressable> */}
                    <Pressable onPress={() => navigation.navigate("CommonScreen", { paramScreen: "about" })}>
                        <Text style={styles.linkViewTextWithRightBorder} dataSet={{ media: ids.linkViewTextWithRightBorder }}>About Us</Text>
                    </Pressable>
                    <Pressable onPress={() => navigation.navigate("ContactScreen")}>
                        <Text style={styles.linkViewTextWithoutRightBorder} dataSet={{ media: ids.linkViewTextWithoutRightBorder }}>Contact Us</Text>
                    </Pressable>
                </View>

                <Image source={require("../assets/footer_line_shape.png")} style={styles.footerLine} />

                <View style={styles.linkView1} dataSet={{ media: ids.linkView1 }}>
                    <Pressable onPress={() => navigation.navigate("CommonScreen", { paramScreen: "terms" })}>
                        <Text style={styles.privacyTextWithRightBorder} dataSet={{ media: ids.privacyTextWithRightBorder }}>Term & condition</Text>
                    </Pressable>
                    <Pressable onPress={() => navigation.navigate("CommonScreen", { paramScreen: "return" })}>
                        <Text style={styles.privacyTextWithRightBorder} dataSet={{ media: ids.privacyTextWithRightBorder }}>Refund policy</Text>
                    </Pressable>
                    <Pressable onPress={() => navigation.navigate("CommonScreen", { paramScreen: "privacy" })}>
                        <Text style={styles.privacyTextWithRightBorder} dataSet={{ media: ids.privacyTextWithRightBorder }}>Privacy policy</Text>
                    </Pressable>
                    <Pressable onPress={() => navigation.navigate("CommonScreen", { paramScreen: "disclaimer" })}>
                        <Text style={styles.privacyTextWithoutRightBorder} dataSet={{ media: ids.privacyTextWithoutRightBorder }}>Disclaimer</Text>
                    </Pressable>
                </View>
            </View>
            {/* end of subview 1 */}

            {/* subview 2 */}
            <View style={styles.footerSubViewCopyright} dataSet={{ media: ids.footerSubViewCopyright }}>
                <Text style={styles.copyRightText} dataSet={{ media: ids.copyRightText }}>© 2023-24 HiIndia. All rights reserved.</Text>
                <Text style={styles.alakmalakText} dataSet={{ media: ids.alakmalakText }}>Design & Developed by Alakmalak Technologies</Text>
            </View>
            {/* end of subview 2 */}

        </View>
    );
}

// format date dd-mm-yyyy
export const formatDate = (date) => {
    const datefor = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
    const month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : "0" + date.getMonth() + 1
    const year = date.getFullYear()

    return datefor + "-" + month + "-" + year;
}

// get only date
export const getOnlyDateNo = (date) => {
    const datefor = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
    return datefor;
}

// get only year
export const dateYear = (date) => {
    const dateyear = date.getFullYear();
    return dateyear;
}

// get month name
export const getMonthName = (date) => {
    // date.toLocaleString('en-US', { month: 'short' }); // {month:'long'}

    const month = moment(date).format("MMM");
    CustomConsole("Month===> ", month);
    return month;
}

// get date format
export const getFormatDate = (date) => {

    const month = moment(date).format("DD MMM, YYYY HH:mm a");
    CustomConsole("Month===> ", month);
    return month;
}

// export const formatDateYMD = (date) => {
//     const datefor = date.getDate()
//     const month = date.getMonth() + 1
//     const year = date.getFullYear()

//     return year + "-" + month + "-" + datefor;
// }

// scroll to top page
export const scrollToTop = () => {
    window.scrollTo(0, 0);
}

